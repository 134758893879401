<template>
    <el-row id="services" class="services" type="flex" justify="center">
        <el-col :span="20">
            <el-row class="service-scope">
                <el-row>
                    <feature-head text="服务范围"/>
                </el-row>
                <el-row>
                    <p class="scope-desc">版图网络科技是“集成升级”的服务核心。用“强大的力量”来实践信念。专注于软件开发，提供准确全面的平台解决方案，独家定制，只为您服务。</p>
                    <el-row type="flex" justify="space-between" class="row-flex">
                        <el-col class="scope-item" :sm="11" :md="5" v-for="(item, index) in serviceScope" :key="index">
                            <div class="item-img-area"><img class="item-img" :src="item.imgURL" alt=""></div>
                            <h3 class="item-title">{{item.title}}</h3>
                        </el-col>
                    </el-row>
                </el-row>
            </el-row>

            <el-row class="our-product">
                <el-row>
                    <feature-head text="我们的产品"/>
                </el-row>
                <el-row>
                    <p class="product-desc desc">我们秉承“用户至上”的原则，将用户需求抱在首位。我们始终坚持不断创新、不断突破，全新全意做好产品的开发与用户的极限体验。
                        从创始至今，我们的业务方向始终不变，专注做好一件事才能更好的追求高品质，这也是我们存在的价值。</p>
                    <el-row type="flex" justify="space-between" class="row-flex">
                        <el-col class="product-item" :sm="11" :md="11" v-for="(item, index) in products" :key="index">
                            <div class="item-img-area"><img class="item-img" :src="item.imgURL" alt=""></div>
                            <h3 class="item-title">{{item.title}}</h3>
                        </el-col>
                    </el-row>
                </el-row>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import FeatureHead from "@/components/common/FeatureHead";
    export default {
        name: "Services",
        data(){
          return {
              serviceScope: [
                  {
                      imgURL: require('@/assets/images/services/website.png'),
                      title: '驻场开发'
                  },
                  {
                      imgURL: require('@/assets/images/services/app.png'),
                      title: '移动开发'
                  },
                  {
                      imgURL: require('@/assets/images/services/custom.png'),
                      title: '系统定制开发'
                  },
                  {
                      imgURL: require('@/assets/images/services/wechat.png'),
                      title: '小程序应用开发'
                  }
              ],
              products: [
                  {
                      imgURL: require("@/assets/images/services/mobile.png"),
                      title: "移动电商",
                      desc: "电子商务行业解决方案中规划的app应用核心产品能够帮助商家快速占有市场，通过良好的用户体验以及清晰的产品定位设计"
                  },
                  {
                      imgURL: require("@/assets/images/services/comunity.png"),
                      title: "移动社区",
                      desc: "基于对社区服务的深度理解和分析，为客户提供整套的智慧社区解决方案。智慧社区\"是一款深度构建化定制的社区服务平台"
                  },
                  {
                      imgURL: require("@/assets/images/services/finance.png"),
                      title: "移动金融",
                      desc: "P2P金融行业解决方案在移动信息化领域的技术优势，凭借对金融证券行业的深刻理解，\"英加诺科技\"为客户提供稳定、高效"
                  },
                  {
                      imgURL: require("@/assets/images/services/fenxiao.png"),
                      title: "企业分销",
                      desc: "针对企业营销过程中的弊端和痛点，英加诺提供一套移动分销解决方案，通过移动互联网对企业分销渠道中的各级经销单位进行高效管理"
                  },
              ]
          }
        },
        components: {
            FeatureHead
        }
    }
</script>

<style scoped>
.services .service-scope .scope-desc{
    margin: 0 0 30px;
    text-align: center;
    color: #797979;
    font-size: 14px;
}

.services .service-scope .scope-item,
.services .our-product .product-item {
    text-align: center;
}

.services .service-scope .scope-item .item-img-area,
.services .our-product .product-item .item-img-area{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 220px;
}

.services .service-scope .scope-item .item-img,
.services .our-product .product-item .item-img{
    width: 220px;
}

.services .service-scope .scope-item .item-title,
.services .our-product .product-item .item-title {
    color: #475168;
    font-weight: 400;
    font-size: 22px;
}

.services .service-scope .scope-item .item-desc,
.services .our-product .item-desc {
    color: #797979;
    font-size: 14px;
    line-height: 1.5;
}

.services .our-product {
    text-align: center;
}

.services .our-product .product-desc {
    margin: 0 0 30px;
}
</style>
