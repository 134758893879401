<template>
  <el-row class="feature-head">
    <el-divider>
      <h1>{{text}}</h1>
    </el-divider>
  </el-row>
</template>

<script>
export default {
  name: "FeatureHead",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.feature-head {
  padding: 30px 0;
}

.feature-head h1 {
  display: block;
  margin: 0;
  font-weight: 200;
  color: rgb(71, 81, 104);
}
</style>
